import type { Prisma } from '@prisma/client';
import { useMachine } from '@xstate/react';
import { initializeApp } from 'firebase/app';
import type { Messaging } from 'firebase/messaging';
import { deleteToken, getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { createContext, useContext, useMemo } from 'react';
import { assign, fromPromise, setup } from 'xstate';
import { z } from 'zod';

export const DESKTOP_NOTIFICATION_DEVICE_TYPE = 'WEB_PUSH_DEVICE';

const firebaseConfig = {
	apiKey: 'AIzaSyDqq-WdQ2OOSHhXD4nkDd4tuPDQen0HBSc',
	projectId: 'ronin-sa',
	messagingSenderId: '566382385780',
	appId: '1:566382385780:web:34f822f44ef567f6e58f21',
};

type Device = Prisma.DeviceGetPayload<{
	select: {
		id: true;
		userId: true;
		type: true;
		pushToken: true;
	};
}>;
const deviceSchema = z.object({
	id: z.string(),
	userId: z.string(),
	type: z.literal('WEB_PUSH_DEVICE'),
	pushToken: z.string(),
});

export enum NotificationError {
	NONE = 0,
	NOT_SUPPORTED = 1,
	INITIALIZATION = 2,
	REFRESH = 3,
	TOGGLE = 4,
}

type MachineContext = {
	workspaceSlug?: string;
	device: Device | null;
	isSupported?: boolean | undefined;
	serviceWorkerRegistration: ServiceWorkerRegistration | null;
	messaging: Messaging | null;
	pushToken: string | null;
	tokenSentToServer: boolean;
	error: NotificationError;
};

const machine = setup({
	types: {} as {
		events: { type: 'ENABLE' | 'DISABLE' } | { type: 'assignError'; data: NotificationError };
		input: {
			device: Device | null;
			workspaceSlug?: string;
		};
		context: MachineContext;
		actions: {
			assignIsSupported: {
				params: { data: boolean };
			};
		};
	},
	actors: {
		isSupported: fromPromise(() => isSupported()),
		loadServiceWorker: fromPromise(() =>
			navigator.serviceWorker.register('/firebase-messaging-sw.js'),
		),
		createFirebaseMessagingApp: fromPromise(async () => {
			const app = initializeApp(firebaseConfig);
			const messaging = getMessaging(app);
			onMessage(messaging, (payload) => {
				console.info('Foreground message received. ', payload);
			});
			return messaging;
		}),
		requestToken: fromPromise(
			async ({
				input,
			}: {
				input: MachineContext;
			}) => {
				const { messaging, serviceWorkerRegistration } = input;
				if (!messaging || !serviceWorkerRegistration) throw new Error('missing context values');
				const pushToken = await getToken(messaging, {
					vapidKey: window.GLOBALS.firebaseVapidKey,
					serviceWorkerRegistration,
				});
				return pushToken;
			},
		),
		sendTokenToServer: fromPromise(async ({ input }: { input: MachineContext }) => {
			const { pushToken, device, workspaceSlug } = input;
			if (!device || !workspaceSlug) throw new Error('missing context values');
			const resp = await deviceAPI(workspaceSlug, {
				_action: 'update',
				deviceId: device.id,
				pushToken,
			});
			const updatedDevice = deviceSchema.parse(resp.device);
			return updatedDevice.pushToken === pushToken;
		}),
		deleteDevice: fromPromise(async ({ input }: { input: MachineContext }) => {
			const { device, workspaceSlug } = input;
			if (!device || !workspaceSlug) throw new Error('missing context values');
			const resp = await deviceAPI(workspaceSlug, { _action: 'delete', deviceId: device.id });
			if (!resp.ok) throw new Error('Unable to delete device');
			return null;
		}),
		createDevice: fromPromise(async ({ input }: { input: MachineContext }) => {
			const { workspaceSlug, pushToken } = input;
			if (!workspaceSlug || !pushToken) throw new Error('missing context values');
			const resp = await deviceAPI(workspaceSlug, { _action: 'create', pushToken });
			const device = deviceSchema.parse(resp.device);
			return device;
		}),
		deleteMessagingToken: fromPromise(async ({ input }: { input: MachineContext }) => {
			const { messaging } = input;
			if (!messaging) throw new Error('missing context values');
			await deleteToken(messaging);
			return null; // sets push token in context to null
		}),
	},
	guards: {
		shouldRefreshToken: ({ context }) =>
			Boolean(
				Notification.permission === 'granted' && context.device?.pushToken && context.workspaceSlug,
			),
		shouldNotRefreshToken: ({ context }) =>
			Boolean(
				Notification.permission !== 'granted' ||
					!context.device?.pushToken ||
					!context.workspaceSlug,
			),
		deviceExists: ({ context }) => Boolean(context.device),
		deviceDoesNotExist: ({ context }) => !context.device,
	},
}).createMachine({
	initial: 'initializing',
	context: ({ input }) => ({
		device: input.device,
		serviceWorkerRegistration: null,
		workspaceSlug: input.workspaceSlug,
		pushToken: null,
		messaging: null,
		tokenSentToServer: false,
		error: NotificationError.NONE,
	}),
	states: {
		initializing: {
			initial: 'checkBrowserSupport',
			always: {
				guard: () =>
					typeof window !== 'undefined' &&
					'Notification' in window &&
					Notification.permission === 'denied',
				target: 'disabled',
			},
			states: {
				checkBrowserSupport: {
					invoke: {
						src: 'isSupported',
						onDone: {
							actions: assign({
								isSupported: ({ event }) => event.output,
							}),
							target: 'loadServiceWorker',
						},
						onError: {
							actions: assign({
								error: () => NotificationError.NOT_SUPPORTED,
							}),
							target: '#disabled',
						},
					},
				},
				loadServiceWorker: {
					invoke: {
						src: 'loadServiceWorker',
						onDone: {
							actions: assign({
								serviceWorkerRegistration: ({ event }) => event.output,
							}),
							target: 'initializeMessagingApp',
						},
						onError: {
							actions: assign({
								error: () => NotificationError.INITIALIZATION,
							}),
							target: '#disabled',
						},
					},
				},
				initializeMessagingApp: {
					invoke: {
						src: 'createFirebaseMessagingApp',
						onDone: {
							actions: assign({
								messaging: ({ event }) => event.output,
							}),
							target: 'checkShouldRefreshToken',
						},
						onError: {
							actions: assign({
								error: () => NotificationError.INITIALIZATION,
							}),
							target: '#disabled',
						},
					},
				},
				checkShouldRefreshToken: {
					always: [
						{
							guard: 'shouldRefreshToken',
							target: 'requestToken',
						},
						{
							guard: 'shouldNotRefreshToken',
							target: '#disabled',
						},
					],
				},
				requestToken: {
					invoke: {
						src: 'requestToken',
						input: ({ context }) => context,
						onDone: {
							actions: assign({
								pushToken: ({ event }) => event.output,
							}),
							target: 'sendTokenToServer',
						},
						onError: {
							actions: assign({
								error: () => NotificationError.REFRESH,
							}),
						},
					},
				},
				sendTokenToServer: {
					invoke: {
						src: 'sendTokenToServer',
						input: ({ context }) => context,
						onDone: {
							target: '#enabled',
							actions: assign({
								tokenSentToServer: ({ event }) => event.output,
							}),
						},
						onError: {
							actions: assign({
								error: () => NotificationError.REFRESH,
							}),
						},
					},
				},
			},
		},
		disabled: {
			id: 'disabled',
			on: {
				ENABLE: 'enabling',
			},
		},
		enabling: {
			initial: 'checkIfDeviceExists',
			states: {
				checkIfDeviceExists: {
					always: [
						{
							guard: 'deviceExists',
							target: 'deleteDevice',
						},
						{
							guard: 'deviceDoesNotExist',
							target: 'requestToken',
						},
					],
				},
				deleteDevice: {
					invoke: {
						src: 'deleteDevice',
						input: ({ context }) => context,
						onDone: {
							actions: assign({
								device: () => null,
							}),
							target: 'requestToken',
						},
						onError: {
							actions: assign({
								error: () => NotificationError.TOGGLE,
							}),
							target: '#disabled',
						},
					},
				},
				requestToken: {
					invoke: {
						src: 'requestToken',
						input: ({ context }) => context,
						onDone: {
							actions: assign({
								pushToken: ({ event }) => event.output,
							}),
							target: 'createDevice',
						},
						onError: {
							actions: assign({
								error: () => NotificationError.TOGGLE,
							}),
							target: '#disabled',
						},
					},
				},
				createDevice: {
					invoke: {
						src: 'createDevice',
						input: ({ context }) => context,
						onDone: {
							actions: assign({
								device: ({ event }) => event.output,
							}),
							target: 'finished',
						},
						onError: {
							actions: assign({
								error: () => NotificationError.TOGGLE,
							}),
							target: '#disabled',
						},
					},
				},
				finished: { type: 'final' },
			},
			onDone: 'enabled',
		},
		enabled: {
			id: 'enabled',
			on: {
				DISABLE: 'disabling',
			},
		},
		disabling: {
			initial: 'deleteToken',
			states: {
				deleteToken: {
					invoke: {
						src: 'deleteMessagingToken',
						input: ({ context }) => context,
						onDone: {
							actions: assign({
								pushToken: ({ event }) => event.output,
								tokenSentToServer: () => false,
							}),
							target: 'deleteDevice',
						},
						onError: {
							actions: assign({
								error: () => NotificationError.TOGGLE,
							}),
							target: '#disabled',
						},
					},
				},
				deleteDevice: {
					invoke: {
						src: 'deleteDevice',
						input: ({ context }) => context,
						onDone: {
							actions: assign({
								device: () => null,
							}),
							target: 'finished',
						},
						onError: {
							actions: assign({
								error: () => NotificationError.TOGGLE,
							}),
							target: '#disabled',
						},
					},
				},
				finished: {
					type: 'final',
				},
			},
			onDone: 'disabled',
		},
	},
});

export function useDesktopNotifications() {
	const context = useContext(DesktopNotificationsContext);

	if (!context) {
		throw new Error('Please wrap your application in the DesktopNotifications provider.');
	}

	return context;
}

type Props = {
	workspaceSlug?: string;
	device: Device | null;
	children: React.ReactNode;
};
export function DesktopPushNotificationsProvider({ device, workspaceSlug, children }: Props) {
	const [current, send] = useMachine(machine, {
		input: {
			device,
			workspaceSlug,
		},
	});

	const value = useMemo(
		() => ({
			handleToggle: () => {
				if (current.matches('enabled')) {
					send({ type: 'DISABLE' });
				} else {
					send({ type: 'ENABLE' });
				}
			},
			isEnabled: current.matches('enabled'),
			...current.context,
		}),
		[current, send],
	);

	return (
		<DesktopNotificationsContext.Provider value={value}>
			{children}
		</DesktopNotificationsContext.Provider>
	);
}

type DesktopNotificationsMachineContext = {
	handleToggle: () => void;
	isEnabled: boolean;
} & MachineContext;
const DesktopNotificationsContext = createContext<DesktopNotificationsMachineContext | null>(null);

async function deviceAPI(workspaceSlug: string, data: Record<string, unknown>) {
	const formData = new FormData();
	for (const [key, value] of Object.entries(data)) {
		formData.set(key, String(value));
	}
	const response = await fetch(`/${workspaceSlug}/web-devices`, {
		method: 'POST',
		credentials: 'include',
		body: formData,
	});
	return await response.json();
}
